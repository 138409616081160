
import { request as axios } from '@/util/request';
import { Component, Mixins, Vue } from 'vue-property-decorator';
import moment, { Moment } from 'moment';
import { PaginationData } from '@/api/operation/customer';
import { RefundData, initRefundData } from '@/api/rider/riderList';
import { setRegion } from '@/view/repository/region';
import storage from 'baimahu/storage';
import { changeLoading } from '@/util/decorators';
import { useMain } from '@/pinia/index';
import { Form as aForm } from 'ant-design-vue';

interface Form {
  status_type: number; //账号状态
  phone_value: string; //电话
  search_value: string; //骑手输入框
  search_type: number; //选择骑手姓名或者电话
  city_value: any; //意向区域
  order_time: Moment[]; //申请时间
  sub_time: Moment[]; //提交时间
}
const SEARCH_TYPE = [
  {
    id: 0,
    value: '骑手ID',
  },
  {
    id: 1,
    value: '骑手姓名',
  },
  {
    id: 2,
    value: '身份证号',
  },
];
const REFUND_STATUS = [
  {
    id: 0,
    value: '全部',
  },
  {
    id: 1,
    value: '待审核',
  },
  {
    id: 3,
    value: '审核通过',
  },
  {
    id: 2,
    value: '审核不通过',
  },
];
function getFormData() {
  return {
    status_type: 0,
    phone_value: '',
    search_value: '',
    search_type: 0,
    city_value: [],
    order_time: [],
    sub_time: [],
  };
}

@Component({
  name: 'KnightList',
})
export default class KnightList extends Vue {
  is_loading: boolean = false;
  searchForm: any;
  pagination = useMain().pagination;
  audioPlay = false; //是否开始自动播放
  form: Form = getFormData();
  ware_category_list: any = []; //意向区域
  province_list: any = []; // 省
  city_list: any = []; // 市
  district_list: any = []; // 区
  area_list: any = []; // 区域列表
  refund_data: any = {
    detail: [],
    current: 1,
    last: 1,
    limit: 1,
    total: 10,
  }; //表格数据
  //搜索条件
  get searchType() {
    return SEARCH_TYPE;
  }
  @changeLoading(['is_loading'])
  async handleSubmit(e) {
    e.preventDefault();
    this.searchForm.validateFields(async (err, values) => {
      if (!err) {
        this.current = 1;
        this.form = {
          ...this.form,
          ...values,
        };
        await this.find();
      }
    });
    return false;
  }
  //获取骑手列表信息
  @changeLoading(['is_loading'])
  async getknightList(body?: any) {
    const bodys: any = {
      ...body,
      current: body?.current ? body.current : this.current,
      limit: 10,
    };
    const res: any = await axios.get('/boss/knight/management', {
      params: {
        ...bodys,
      },
    });
    this.refund_data = res.data;
  }
  //搜索
  @changeLoading(['is_loading'])
  async find() {
    await this.getknightList(this.getSearChData());
  }
  getSearChData() {
    const start_time = this.form.sub_time[0]
      ? Math.floor(+this.form.sub_time[0].startOf('day').format('x') / 1000)
      : '';
    const end_time = this.form.sub_time[1]
      ? Math.floor(+this.form.sub_time[1].endOf('day').format('x') / 1000)
      : '';
    const order_stime = this.form.order_time[0]
      ? Math.floor(+this.form.order_time[0].startOf('day').format('x') / 1000)
      : '';
    const order_etime = this.form.order_time[1]
      ? Math.floor(+this.form.order_time[1].endOf('day').format('x') / 1000)
      : '';
    const send_data: any = {
      register_id: this.form.search_value,
      name: this.form.search_value,
      id_number: this.form.search_value,
      mobile: this.form.phone_value,
      account_status: this.form.status_type,
      province_id: this.form.city_value[0],
      city_id: this.form.city_value[1],
      district_id: this.form.city_value[2],
      register_start_time: order_stime,
      register_end_time: order_etime,
      submit_start_time: start_time,
      submit_end_time: end_time,
      limit: 10,
      status: this.statues,
    };
    if (this.form.order_time.length <= 0) {
      delete send_data.register_start_time;
      delete send_data.register_end_time;
    }
    if (this.form.sub_time.length <= 0) {
      delete send_data.submit_start_time;
      delete send_data.submit_end_time;
    }
    if (this.form.status_type == 0) {
      delete send_data.account_status;
    }
    if (this.form.search_value == '') {
      delete send_data.name;
      delete send_data.id_number;
      delete send_data.register_id;
    }
    if (this.form.phone_value == '') {
      delete send_data.mobile;
    }
    if (this.form.search_type == 0) {
      delete send_data.name;
      delete send_data.id_number;
    } else if (this.form.search_type == 1) {
      delete send_data.register_id;
      delete send_data.id_number;
    } else {
      delete send_data.name;
      delete send_data.register_id;
    }
    return send_data;
  }
  //切换状态
  statues: any = 0;
  async qiehuan(statues: number) {
    this.statues = statues;
    await this.getknightList(this.getSearChData());
  }
  //骑手状态
  get refundStatus() {
    return REFUND_STATUS;
  }
  //分页
  current: number = 1;
  paginationOption(data: any) {
    return {
      showTotal: () => `共${data.total}条，每页${data.limit}条`,
      total: data.total || 0,
      pageSize: data.limit || 1,
      current: +data.current || 1,
    };
  }
  // 切换分页
  @changeLoading(['is_loading'])
  async handleChange(data: any) {
    this.current = data.current;
    await this.getknightList(this.getSearChData());
  }
  //清除
  clearn() {
    this.form.search_value = '';
    this.form.status_type = 0;
    this.form.phone_value = '';
    this.form.search_type = 0;
    this.form.city_value = 0;
    this.form.order_time = [];
    this.form.sub_time = [];
    this.statues = 0;
    this.searchForm.resetFields();
    this.current = 1;
    this.find();
  }
  oldSearchData(path: string) {
    this.pagination.knightList = {
      ...this.form,
      statues: this.statues,
      current: this.refund_data.current,
    };
    this.$router.push(path);
  }
  async created() {
    this.searchForm = (aForm as any)?.createForm(this, {
      name: 'form',
    });
    await setRegion(); // 设置 localStorage 中的 region_list
    this.province_list = storage.getItem('region_list');
    if (this.pagination?.knightList) {
      Object.keys(this.pagination.knightList).forEach(item => {
        if (item === 'statues') {
          this.statues = this.pagination.knightList[item];
        } else if (item !== 'current') {
          this.form[item] = this.pagination.knightList[item];
        }
      });
      const send_data = {
        ...this.getSearChData(),
        current: this.pagination.knightList.current,
      };
      await this.getknightList(send_data);
      delete this.pagination.knightList;
      return;
    }
    await this.getknightList();
  }
  changeTime(val) {
    return moment(val).format('YYYY-MM-DD HH:mm:ss');
  }
}
